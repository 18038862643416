import { Tooltip, withStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useIsMobile } from "../hooks/common.hooks";
import { primaryColor } from "../scss/colors.scss";
import { validatePassword } from "../utils/password-validate";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    color: primaryColor,
    fontSize: 12,
    border: `1px solid ${primaryColor}`,
  },
}))(Tooltip);

const PasswordTooltip = ({ open = false, password, placement }) => {
  const checkValidatePassword = useMemo(() => {
    return validatePassword(password);
  }, [password]);
  const { rules = {} } = checkValidatePassword;
  const {
    lowerValidate = false,
    minLenValidate = false,
    numberValidate = false,
    upperValidate = false,
    specialCharValidate = false,
  } = rules;
  const isMobile = useIsMobile();
  return (
    <LightTooltip
      placement={placement ?? `${isMobile ? "top" : "right"}-start`}
      className="password-tooltip"
      open={open}
      title={
        <div className="password-validate-tooltip">
          <div>Password Requirement: </div>
          <div
            className={classNames("password-validate", {
              "is-done": minLenValidate,
            })}
          >
            <span>{minLenValidate ? <CheckIcon /> : <ClearIcon />}</span>{" "}
            Minimum 8 digits
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": upperValidate,
            })}
          >
            <span>{upperValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 upper case letters (A – Z)
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": lowerValidate,
            })}
          >
            <span>{lowerValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 Lower case letters (a – z)
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": numberValidate,
            })}
          >
            <span>{numberValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 number (0 – 9)
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": specialCharValidate,
            })}
          >
            <span>{specialCharValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 special character
          </div>
        </div>
      }
    >
      <div />
    </LightTooltip>
  );
};

PasswordTooltip.propTypes = {};

export default PasswordTooltip;
