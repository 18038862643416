import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { useLocation } from "@reach/router";
import {
  SIGN_UP_COLLECT_BASIC_INFO_STEP,
  SIGN_UP_PROCESS_STEP,
} from "../../../commons/signup.constants";
import BackToLoginButton from "../../../components/back-to-login-button";
import ExternalAccountsConnectUiDialog from "../../../components/ExternalAccountsConnectUIDialog/external-accounts-connect-ui-dialog";
import CheckUserLoggedInContext from "../../../context/check-user-logged-in-context";
import ConnectBankContext from "../../../context/connect-bank-context";
import SignupContext from "../../../context/sign-up-context";
import {
  useBasiqConsent,
  useExternalFinanceAdapter,
  useYodleeConnect,
} from "../../../hooks/account.hooks";
import ConnectBankContainer from "./connect-bank-container";
import PhoneVerifyContainer from "./phone-verify-container";
import SignupConnectProperty from "./sign-up-connect-property";
import NewSignupForm from "./sign-up-form-new";
import SignUpRetrieveBankData from "./sign-up-retrieve-bank-data";
import SignupSetBuyingGoal from "./sign-up-set-buying-goal";
import SignUpSuccess from "./sign-up-success";
import { EXTERNAL_FINANCE_ADAPTER } from "../../../commons/enum";
import { setQueryParamToPathAndSearch } from "../../../utils";

const EXTERNAL_ACCOUNTS_CONNECT_UI_CONTAINER_ID =
  "container-external-accounts-connect-ui";
const PROCESS_QUERY_PARAM = "signup-process";
const NewSignup = ({ signupProcess, setSignupProcess, params, setParams }) => {
  const location = useLocation();
  const cachedProcess = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(PROCESS_QUERY_PARAM);
  }, [location]);
  useEffect(() => {
    const redirected = new URLSearchParams(location.search).get("redirected");
    if (!cachedProcess || !location || redirected) {
      return;
    }
    setSignupProcess(cachedProcess);
    const url = new URL(location.href);
    url.searchParams.set("redirected", "true");
    location.search = url.search;
  }, [cachedProcess, setSignupProcess, location]);

  const { setCheckUserLoggedIn } = useContext(CheckUserLoggedInContext);

  const { data: adapterType } = useExternalFinanceAdapter();
  const { open: openBasiqConnect } = useBasiqConsent();
  const { connect: connectExternalAccounts } = useYodleeConnect();

  const connectBankSuccessRef = useRef(false);
  const [
    externalAccountsConnectUIShown,
    setExternalAccountsConnectUIShown,
  ] = useState(false);
  const [connectedInstitutionAccount, setConnectInstitutionAccount] = useState(
    null
  );

  const [timeExpire, setTimeExpire] = useState(0);
  const [fieldError, setFieldError] = useState({});
  const [collectBasicInfoStep, setCollectBasicInfoStep] = useState(
    SIGN_UP_COLLECT_BASIC_INFO_STEP.FULL_NAME
  );

  const { phoneNumber } = params;

  const onBeforeRedirect = useCallback((state) => {
    const basiqRedirectPath = state.appRoute;
    if (!basiqRedirectPath) return state;
    const newRoute = setQueryParamToPathAndSearch(
      basiqRedirectPath,
      PROCESS_QUERY_PARAM,
      SIGN_UP_PROCESS_STEP.CONNECT_PROPERTY
    );
    const cancelAppRoute = setQueryParamToPathAndSearch(
      basiqRedirectPath,
      PROCESS_QUERY_PARAM,
      SIGN_UP_PROCESS_STEP.CONNECT_BANK
    );
    return {
      ...state,
      appRoute: newRoute,
      cancelAppRoute,
    };
  }, []);
  const startConnectingExternalAccounts = () => {
    if (adapterType === EXTERNAL_FINANCE_ADAPTER.BasicQ) {
      openBasiqConnect({
        onBeforeRedirect,
      });
    } else if (adapterType === EXTERNAL_FINANCE_ADAPTER.Yodlee) {
      setExternalAccountsConnectUIShown(true);
      connectExternalAccounts({
        targetElementId: EXTERNAL_ACCOUNTS_CONNECT_UI_CONTAINER_ID,
        onSuccess: () => {
          setCheckUserLoggedIn(true);
          connectBankSuccessRef.current = true;
        },
        onError: () => {
          connectBankSuccessRef.current = false;
        },
        onClose: (closeData) => {
          setExternalAccountsConnectUIShown(false);
          if (connectBankSuccessRef.current) {
            const { sites = [] } = closeData;
            const connectedSite = sites?.[0];
            setConnectInstitutionAccount({
              institutionAccountId: connectedSite?.providerAccountId,
              additionalStatus: connectedSite?.additionalStatus,
            });
            setSignupProcess(SIGN_UP_PROCESS_STEP.RETRIEVE_BANK_DATA);
          }
        },
      });
    }
  };

  const renderSignUpStep = () => {
    switch (signupProcess) {
      case SIGN_UP_PROCESS_STEP.COLLECT_BASIC_INFO:
        return <NewSignupForm autoFocus={false} />;
      case SIGN_UP_PROCESS_STEP.VERIFY_PHONE_NUMBER:
        return (
          <PhoneVerifyContainer phone={phoneNumber} timeExpire={timeExpire} />
        );
      case SIGN_UP_PROCESS_STEP.CONNECT_BANK:
        return <ConnectBankContainer />;
      case SIGN_UP_PROCESS_STEP.RETRIEVE_BANK_DATA:
        return <SignUpRetrieveBankData />;
      case SIGN_UP_PROCESS_STEP.CONNECT_PROPERTY:
        return <SignupConnectProperty />;
      case SIGN_UP_PROCESS_STEP.SET_BUYING_GOAL:
        return <SignupSetBuyingGoal />;
      case SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS:
        return <SignUpSuccess />;
      default:
        return null;
    }
  };

  return (
    <SignupContext.Provider
      value={{
        collectBasicInfoStep,
        setCollectBasicInfoStep,
        signupProcess,
        setSignupProcess,
        params,
        setParams,
        fieldError,
        setFieldError,
        timeExpire,
        setTimeExpire,
        connectedInstitutionAccount,
      }}
    >
      <ConnectBankContext.Provider
        value={{
          startConnectingExternalAccounts,
          connectBankSuccessRef,
        }}
      >
        {adapterType === EXTERNAL_FINANCE_ADAPTER.Yodlee && (
          <ExternalAccountsConnectUiDialog
            id={EXTERNAL_ACCOUNTS_CONNECT_UI_CONTAINER_ID}
            open={externalAccountsConnectUIShown}
          />
        )}
        <div className="signup-process-container">{renderSignUpStep()}</div>
      </ConnectBankContext.Provider>
      <BackToLoginButton />
    </SignupContext.Provider>
  );
};

export default NewSignup;
